<template>
  <div>
    <b-field label="Generate Voucher" label-position="on-border">
      <b-input placeholder="Coins" v-model="value" type="number" />
      <p class="control">
        <b-button
          class="button is-primary"
          :style="{
            backgroundColor: '#1062FE',
          }"
          :loading="generating"
          @click="generate"
        >
          Generate!
        </b-button>
      </p>
    </b-field>
    <table>
      <tr v-if="generatedVouchers.length > 0">
        <th>Code</th>
        <th>Value</th>
      </tr>
      <tr v-for="voucher in generatedVouchers" :key="voucher.code">
        <td>{{ voucher.code }}</td>
        <td>{{ voucher.value }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      generating: false,
      value: undefined,
      generatedVouchers: [],
    };
  },
  methods: {
    generate() {
      this.claiming = true;
      axios({
        method: "POST",
        url: "/voucher/gen/" + this.value,
      })
        .then((response) => {
          console.log(response.data);
          this.generatedVouchers.push({
            code: response.data.code,
            value: response.data.value,
          });
        })
        .finally(() => {
          this.claiming = false;
        });
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}
table td,
table th {
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0e9afe;
  color: white;
}
</style>