<template>
  <section class="hero">
    <div class="hero-body">
      <div class="card">
        <div class="card-header">
          <p class="card-header-title">Vouchers</p>
        </div>
        <footer
          class="card-footer"
          :style="{
            padding: '16px',
          }"
        >
        <GenerateVoucher />
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import GenerateVoucher from '../components/GenerateVoucher.vue'

export default {
  components: {
    GenerateVoucher
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.card:not(:last-child) {
  margin-bottom: 25px;
}
</style>